<template>
  <div>
    <Toolbar class="flex w-screen">
      <template #start>
        <div class="flex justify-content-center items-center gap-1">
          <img
            src="../assets/logoCREPH.png"
            alt="LogoCREPH"
            style="height: 3.7rem; width: 3rem"
          />
          <Button @click="showComponent('Pma')" label="Lista de Fichas" text plain />
          <Button
            v-if="isAdmin || isOperadorCentral"
            @click="showComponent('UserList')"
            label="Lista de Usuários"
            text
            plain
          />
        </div>
      </template>
      
      <template #end>
        <div class="flex items-center gap-2">
          <LogoutButton />
        </div>
      </template>
    </Toolbar>

    <div class="flex justify-content-center">
      <!-- Exibe os componentes dinamicamente -->
      <Pma v-if="currentComponent === 'Pma'" />
      <!-- Adicione o componente de Lista de Usuários aqui quando necessário -->
      <ListaDeUsuarios v-if="currentComponent === 'UserList'" />
    </div>
    
    <div 
      class="flex justify-content-center align-content-center card bg-white m-3 border-1 border-round-xl h-30rem shadow-3 surface-border"
      v-if="!currentComponent">
      <a class="text-5xl text-center"> 
        Bem vindo(a), {{ username }}!
      </a>
    </div>
  </div>
</template>

<script>
import Toolbar from "primevue/toolbar";
import Pma from "./Pma.vue";
import LogoutButton from "./LogoutButton.vue";
import Button from "primevue/button";
import ListaDeUsuarios from "./ListaDeUsuarios.vue"; // Importar quando necessário

export default {
  components: {
    Button,
    Toolbar,
    Pma,
    LogoutButton,
    ListaDeUsuarios, // Registrar o componente quando necessário
  },
  data() {
    return {
      currentComponent: null,
      isAdmin: false, // Inicializa isAdmin como false
      isOperadorCentral: false,
    };
  },
  computed: {
    username() {
      return localStorage.getItem('username') || 'Usuário';
    }
  },
  methods: {
    showComponent(component) {
      if (this.currentComponent === component) {
        this.currentComponent = null; // Oculta o componente se já estiver visível
      } else {
        this.currentComponent = component; // Mostra o componente selecionado
      }
    },
    checkAdminStatus() {
      // Verifica o status de administrador no localStorage
      const isAdmin = localStorage.getItem("isAdmin");
      if (isAdmin === "true") {
        this.isAdmin = true;
      }
    },
    checkOperadorStatus() {
      if (localStorage.getItem("cargo") === "Operador Central") {
        return this.isOperadorCentral = true;
      }
    },
  },
  created() {
    // Verifica o status de administrador quando o componente é criado
    this.checkAdminStatus();
    this.checkOperadorStatus();
  },
};
</script>

<style scoped>
@media (max-width: 576px) {
  button {
    width: 5.6em;
  }
}

.header-panel {
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  /* Adicione regras de mídia aqui */
}
</style>