<template>
  <div class="situacao-atual-pma">
    <h3 class="text-center">Status Geral do {{ selectedPmaName }}</h3>
    <div class="flex flex-column gap-2">
      <div>Total de Pacientes: {{ totalPacientes }}</div>
      <div>
        Pacientes
        <a class="text-white bg-orange-500 p-1 border-round-lg"
          >em Atendimento:</a
        >
        {{ emAtendimento }}
      </div>
      <div>
        Pacientes
        <a class="text-white bg-green-700 p-1 border-round-lg">Liberados:</a>
        {{ liberados }}
      </div>
      <div>
        Pacientes
        <a class="text-white bg-red-700 p-1 border-round-lg">Removidos:</a>
        {{ removidos }}
      </div>
      <div>
        Média de Tempo de Atendimento: {{ mediaTempoAtendimento }} minutos
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    fichasMedicas: {
      type: Array,
      default: () => [], // Garante que `fichasMedicas` seja um array por padrão
    },
  },
  data() {
    return {
      selectedPmaName: "", // Inicializa o nome do PMA como uma string vazia
      maxAttempts: 3, // Número máximo de tentativas
    };
  },
  mounted() {
    this.setSelectedPmaName(); // Chama a função ao montar o componente
  },
  methods: {
    async setSelectedPmaName() {
  const pmaId = localStorage.getItem("pma_id");
  if (!pmaId) {
    console.error("pma_id não encontrado no localStorage.");
    return;
  }

  let tentativasRestantes = 2; // Definindo 2 tentativas

  while (tentativasRestantes > 0) {
    try {
      const response = await fetch(
        `https://backendcreph.onrender.com/api/pmas/${pmaId}`
      );
      const data = await response.json();
      this.selectedPmaName = data.nome_pma;
      return; // Sai do loop ao obter sucesso
    } catch (error) {
      console.error("Erro ao buscar nome do PMA:", error);
      tentativasRestantes--;

      if (tentativasRestantes === 0) {
        // Exibe o erro após todas as tentativas falharem
        console.error("Falha após múltiplas tentativas.");
        return;
      }
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Pausa de 1 segundo antes de tentar novamente
    }
  }
},
  },
  setup(props) {
    const totalPacientes = computed(() => props.fichasMedicas.length);

    const emAtendimento = computed(
      () =>
        props.fichasMedicas.filter(
          (ficha) => ficha.liberado === "Em atendimento"
        ).length
    );

    const liberados = computed(
      () =>
        props.fichasMedicas.filter((ficha) => ficha.liberado === "Liberado")
          .length
    );

    const removidos = computed(
      () =>
        props.fichasMedicas.filter((ficha) => ficha.liberado === "Removido")
          .length
    );

    const parseTime = (timeString) => {
      const [hours, minutes, seconds] = timeString.split(":").map(Number);
      return new Date(0, 0, 0, hours, minutes, seconds);
    };

    const mediaTempoAtendimento = computed(() => {
      const tempos = props.fichasMedicas
        .filter((ficha) => ficha.hora_saida && ficha.hora_entrada)
        .map((ficha) => {
          const entrada = parseTime(ficha.hora_entrada);
          const saida = parseTime(ficha.hora_saida);
          return (saida - entrada) / 60000; // Converte milissegundos para minutos
        });

      if (tempos.length === 0) return 0;
      const soma = tempos.reduce((a, b) => a + b, 0);
      return (soma / tempos.length).toFixed(2);
    });

    return {
      totalPacientes,
      emAtendimento,
      liberados,
      removidos,
      mediaTempoAtendimento,
    };
  },
};
</script>

<style scoped>
h3 {
  margin-top: 0;
  margin-bottom: 10px;
}
.situacao-atual-pma {
  margin: 16px 16px 0 16px;
  background: #f4f4f4;
  padding: 1rem;
  border-radius: 8px;
  color: black;
}
</style>
