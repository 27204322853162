<template>
  <div class="flex flex-column justify-content-center w-10">
    <Dialog :closable="false" v-model:visible="loading" modal>
      <ProgressSpinner />
    </Dialog>
    <Dialog header="Editar PMA" v-model:visible="editDialogVisible" modal>
      <InputText
        class="w-full text-center"
        v-model="editPmaName"
        placeholder="Nome do PMA"
      />
      <h4>Selecione os novos usuários do PMA</h4>
      <multiselect
        v-model="editSelectedUsers"
        :options="sortedUsers"
        track-by="id"
        label="username"
        multiple
        placeholder="Selecione os usuários"
        :close-on-select="false"
      >
        <template #option="{ option }">
          <div class="user-option">
            <span>{{ option.username }}</span> -
            <span>{{ option.cargo }}</span>
          </div>
        </template>
        <template #selected-option="{ option }">
          <div class="selected-user">
            <span>{{ option.username }}</span> -
            <span>{{ option.cargo }}</span>
          </div>
        </template>
      </multiselect>
      <button class="mt-3 w-full h-2rem" @click="updatePma">
        Atualizar PMA
      </button>
    </Dialog>
    <div>
      <div v-if="isAdmin || isOperadorCentral">
        <button @click="toggleForm" class="w-full h-3rem my-3">
          Criar Novo PMA
        </button>
        <div v-if="showForm" class="flex flex-column align-items-center">
          <InputText
            v-model="novoPmaNome"
            placeholder="Nome do PMA"
            class="w-full"
            required
          />
          <h4 class="text-center">Selecione os usuários do novo PMA</h4>
          <multiselect
            v-model="selectedUsers"
            :options="sortedUsers"
            track-by="id"
            label="username"
            multiple
            placeholder="Selecione os usuários"
            :close-on-select="false"
            required
          >
            <template #option="{ option }">
              <div class="user-option">
                <span>{{ option.username }}</span> -
                <span>{{ option.cargo }}</span>
              </div>
            </template>
            <template #selected-option="{ option }">
              <div class="selected-user">
                <span>{{ option.username }}</span> -
                <span>{{ option.cargo }}</span>
              </div>
            </template>
          </multiselect>
          <button @click="criarPma" class="w-4 h-2rem mt-3">Criar PMA</button>
        </div>
      </div>
    </div>
    <h2 class="flex justify-content-center text-center">
      Selecione o PMA clicando no nome desejado
    </h2>
    <ul class="text-center">
      <li
        class="flex justify-content-between align-items-center"
        v-for="pma in pmas"
        :key="pma.id"
        @click="selectPma(pma.id)"
      >
        {{ pma.name }}
        <div class="flex justify-content-end gap-2 w-3">
          <button
            class="sm:w-5 w-8 h-2rem buttonEdit"
            v-if="isAdmin || isOperadorCentral"
            @click.stop="openEditDialog(pma)"
          >
            <span>Editar</span>
          </button>
          <button
            class="w-5 h-2rem buttonDelete"
            v-if="isAdmin"
            @click.stop="confirmDeletePma(pma)"
          >
            <span class="material-symbols-outlined">Delete</span>
          </button>
        </div>
      </li>
    </ul>

    <p v-if="errorMessage">{{ errorMessage }}</p>
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";
import Multiselect from "vue-multiselect";
import "/Users/souza/Documents/projetoCREPHVUE/crephsystem/node_modules/vue-multiselect/dist/vue-multiselect.esm.css";
import InputText from "primevue/inputtext";
export default {
  name: "PmaName",
  components: {
    Dialog,
    ProgressSpinner,
    Multiselect,
    InputText,
  },
  data() {
    return {
      pmas: [],
      selectedUsers: [],
      editSelectedUsers: [],
      showForm: false, // Controle para mostrar/ocultar o formulário
      novoPmaNome: "",
      editPmaName: "",
      users: [],
      currentPmaUsers: [], // Adicionando para armazenar usuários do PMA atual
      userId: null, // Adicione um estado para armazenar o ID do usuário
      isAdmin: false,
      isOperadorCentral: false,
      loading: false,
      errorMessage: "",
      maxAttempts: 3, // Número máximo de tentativas
      editDialogVisible: false,
      editingPmaId: null,
    };
  },
  computed: {
    sortedUsers() {
      return this.users
        .slice()
        .sort((a, b) => a.username.localeCompare(b.username));
    },
  },
  created() {
    this.fetchPmas();
    this.checkAdminStatus();
    this.checkOperadorStatus();
    this.fetchUsers();
    this.userId = localStorage.getItem("userId");
  },
  methods: {
    formatOptionLabel(option) {
      return `${option.username} (${option.cargo})`;
    },
    toggleForm() {
      this.showForm = !this.showForm;
    },
    // Método para confirmar a exclusão do PMA
    async confirmDeletePma(pma) {
      const confirmDelete = confirm(
        `Tem certeza de que deseja excluir o PMA "${pma.name}"?`
      );

      if (confirmDelete) {
        const confirmDeleteFichas = confirm(
          "Todas as fichas associadas a este PMA também serão excluídas. Deseja continuar?"
        );

        if (confirmDeleteFichas) {
          await this.deletePma(pma.id);
        }
      }
    },

    // Método que realiza a exclusão do PMA e fichas associadas
    async deletePma(pmaId) {
      let tentativasRestantes = this.maxAttempts; // Define o número máximo de tentativas
      this.loading = true;

      while (tentativasRestantes > 0) {
        try {
          const response = await fetch(
            `https://backendcreph.onrender.com/api/pma/${pmaId}`,
            {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (response.ok) {
            alert("PMA e fichas associadas excluídos com sucesso.");
            this.fetchPmas(); // Atualiza a lista de PMAs após a exclusão
            return; // Encerra o loop de tentativas ao obter sucesso
          } else {
            const errorData = await response.json();
            console.error("Erro ao excluir o PMA:", errorData.message);
            throw new Error(`Erro ao excluir o PMA: ${errorData.message}`);
          }
        } catch (error) {
          console.error("Erro ao excluir o PMA:", error);
          tentativasRestantes--;

          if (tentativasRestantes > 0) {
            console.log(
              `Tentativa falhou. Tentativas restantes: ${tentativasRestantes}.`
            );
            await new Promise((resolve) => setTimeout(resolve, 1000)); // Espera 1 segundo antes da próxima tentativa
          } else {
            alert(
              "Erro ao excluir o PMA. Por favor, tente novamente mais tarde."
            );
          }
        } finally {
          this.loading = false;
        }
      }
    },

    async fetchPmas() {
      const userId = localStorage.getItem("userId");
      this.loading = true;
      let tentativasRestantes = this.maxAttempts;
      if (!userId) {
        console.error("User ID is missing");
        return;
      }
      while (tentativasRestantes > 0) {
        try {
          const response = await fetch(
            `https://backendcreph.onrender.com/api/pmas?user_id=${userId}`
          );
          if (response.ok) {
            const pmas = await response.json();
            this.pmas = pmas; // Agora armazena todos os PMAs que o usuário tem acesso
            this.errorMessage = "";
            this.loading = false;
            return;
          } else {
            const errorData = await response.json();
            throw new Error(`Erro ao buscar PMAs: ${errorData.message}`);
          }
        } catch (error) {
          console.error("Erro ao buscar PMAs:", error);
          this.errorMessage = `Aguarde...`;
          tentativasRestantes--;
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }

      if (tentativasRestantes === 0) {
        this.loading = false;
        this.errorMessage =
          "Falha ao buscar PMAs após várias tentativas. Por favor, tente novamente mais tarde.";
      }
    },
    selectPma(pmaId) {
      localStorage.setItem("pma_id", pmaId);
      this.$router.push({ name: "FichaMedica", params: { pmaId } });
    },
    async fetchUsers() {
      let tentativasRestantes = this.maxAttempts;
      this.loading = true;
      while (tentativasRestantes > 0) {
        try {
          const response = await fetch(
            "https://backendcreph.onrender.com/api/admin/users",
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          this.users = await response.json();
          return;
        } catch (error) {
          console.error("Erro ao buscar usuários:", error);
        }
      }
      if (tentativasRestantes === 0) {
        this.loading = false;
        this.errorMessage =
          "Falha ao buscar usuários após várias tentativas. Por favor, tente novamente mais tarde.";
      }
    },
    async criarPma() {
      let tentativasRestantes = this.maxAttempts;
      this.loading = true;
      const pmaData = {
        name: this.novoPmaNome,
        user_ids: this.selectedUsers.map((user) => user.id),
      };

      while (tentativasRestantes > 0) {
        try {
          const response = await fetch(
            "https://backendcreph.onrender.com/api/create_pma",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              body: JSON.stringify(pmaData),
            }
          );

          if (response.ok) {
            alert("PMA criado com sucesso!");
            this.fetchPmas(); // Atualiza a lista de PMAs após a criação
            this.novoPmaNome = ""; // Limpa o campo de nome do novo PMA
            this.selectedUsers = []; // Limpa os usuários selecionados
            return;
          } else if (response.status === 400) {
            const errorData = await response.json();
            if (errorData.message === "PMA já existe.") {
              alert(
                "Erro: Já existe um PMA com este nome. Escolha um nome diferente."
              );
              return;
            } else {
              alert(`Erro: ${errorData.message}`);
            }
          } else {
            console.error("Erro ao criar PMA:", response);
            alert("Erro desconhecido ao criar o PMA.");
          }
        } catch (error) {
          console.error("Erro ao criar PMA:", error);
          alert("Erro de rede. Por favor, tente novamente mais tarde.");
        } finally {
          this.loading = false;
        }
        tentativasRestantes--;
      }

      if (tentativasRestantes === 0) {
        this.loading = false;
        alert(
          "Falha ao criar PMA após várias tentativas. Por favor, tente novamente mais tarde."
        );
      }
    },
    async openEditDialog(pma) {
      this.editPmaName = pma.name;
      this.editDialogVisible = true;
      this.editingPmaId = pma.id;

      // Reseta os arrays ao abrir o diálogo
      this.currentPmaUsers = [];
      this.editSelectedUsers = [];

      let tentativasRestantes = 2; // Defina o número de tentativas

      while (tentativasRestantes > 0) {
        try {
          this.loading = true;
          const response = await fetch(
            `https://backendcreph.onrender.com/api/pma/${pma.id}/users`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response.ok) {
            const users = await response.json();
            // Atualiza os arrays com os dados da API
            this.currentPmaUsers = users;
            this.editSelectedUsers = users; // Sincroniza os usuários editados
            return; // Sai da função após sucesso
          } else {
            console.error("Erro ao buscar usuários do PMA");
          }
        } catch (error) {
          console.error("Erro ao buscar usuários do PMA:", error);
        } finally {
          this.loading = false;
        }

        tentativasRestantes--; // Decrementa o número de tentativas
      }

      if (tentativasRestantes === 0) {
        alert(
          "Falha ao buscar usuários após várias tentativas. Por favor, tente novamente mais tarde."
        );
      }
    },
    async updatePma() {
  let tentativasRestantes = this.maxAttempts;
  this.loading = true;

  // Dados do PMA para atualização, incluindo o nome e os IDs dos usuários associados
  const pmaData = {
    name: this.editPmaName,
    user_ids: this.editSelectedUsers.map((user) => user.id),
  };

  while (tentativasRestantes > 0) {
    try {
      const response = await fetch(
        `https://backendcreph.onrender.com/api/pma/${this.editingPmaId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(pmaData),
        }
      );

      if (response.ok) {
        // Atualiza a lista de PMAs após a edição
        this.fetchPmas();
        alert("PMA editado com sucesso!");
        this.editDialogVisible = false;
        return; // Saia da função após sucesso
      } else {
        console.error("Erro ao atualizar PMA");
      }
    } catch (error) {
      console.error("Erro ao atualizar PMA:", error);
      this.errorMessage = "Erro de rede. Por favor, tente novamente mais tarde.";
    }

    tentativasRestantes--;

    if (tentativasRestantes > 0) {
      console.log(`Tentativa falhou. Restam ${tentativasRestantes} tentativas.`);
    } else {
      this.errorMessage =
        "Falha ao atualizar o PMA após várias tentativas. Por favor, tente novamente mais tarde.";
    }
  }

  this.loading = false;
},

    checkAdminStatus() {
      const isAdmin = localStorage.getItem("isAdmin");
      if (isAdmin === "true") {
        this.isAdmin = true;
      }
    },
    checkOperadorStatus() {
      const cargo = localStorage.getItem("cargo");
      if (cargo === "Operador Central") {
        this.isOperadorCentral = true;
      }
    },
  },
};
</script>

<style scoped>
/* Estilos específicos para este componente */
.buttonDelete {
  color: white;
  background-color: red;
}
.buttonDelete:hover {
  color: red;
  background-color: rgb(252, 221, 221);
}
.buttonEdit {
  color: white;
}
.buttonEdit:hover {
  color: #00008b;
}
.buttonDif {
  background-color: white;
}
.buttonDif:hover {
  background-color: #00008b;
}
ul {
  list-style-type: none;
  padding: 0;
}

li {
  align-content: center;
  height: 70px;
  margin-bottom: 10px;
  /* background: rgb(2,0,36); */
  background: #00008b;
  color: white;
  cursor: pointer;
  padding: 10px;
  border: 2px solid darkblue;
  border-radius: 15px;
  transition: background-color 0.3s;
}
li:hover {
  background-color: darkblue;
}
</style>
