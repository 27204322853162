<template>
  <div class="border-solid border-100 p-3 my-3">
    <h2 class="text-center">Escala de Glasgow</h2>
    <div class="flex justify-content-between">
      <div class="surface-100 p-3 border-round-xl mb-2">
        <h3 class="text-center">Abertura Ocular</h3>
        <hr class="w-8" />
        <div v-for="item in aberturaOcularOptions" :key="item.label">
          <RadioButton
            class="mr-2"
            :inputId="item.label"
            v-model="aberturaOcular"
            :value="item.value"
          />
          <label :for="item.label">{{ item.label }}</label>
        </div>
      </div>
      <div class="surface-100 p-3 border-round-xl mb-2">
        <h3 class="text-center">Resposta Verbal</h3>
        <hr class="w-8" />
        <div v-for="item in respostaVerbalOptions" :key="item.label">
          <RadioButton
            class="mr-2"
            :inputId="item.label"
            v-model="respostaVerbal"
            :value="item.value"
          />
          <label :for="item.label">{{ item.label }}</label>
        </div>
      </div>
      <div class="surface-100 p-3 border-round-xl mb-2">
        <h3 class="text-center">Melhor Resposta Motora</h3>
        <hr class="w-8" />
        <div v-for="item in melhorRespostaMotoraOptions" :key="item.label">
          <RadioButton
            class="mr-2"
            :inputId="item.label"
            v-model="melhorRespostaMotora"
            :value="item.value"
          />
          <label :for="item.label">{{ item.label }}</label>
        </div>
      </div>
      <div class="surface-100 p-3 border-round-xl mb-2">
        <h3 class="text-center">Reatividade Pupilar</h3>
        <hr class="w-8" />
        <div v-for="item in reatividadePupilarOptions" :key="item.label">
          <RadioButton
            class="mr-2"
            :inputId="item.label"
            v-model="reatividadePupilar"
            :value="item.value"
          />
          <label :for="item.label">{{ item.label }}</label>
        </div>
      </div>
    </div>
    <div class="surface-100 p-3 border-round-xl flex justify-content-center">
      <h3 class="text-center m-0">Total de Pontos: {{ totalPontos }}</h3>
    </div>
  </div>
</template>

<script>
import { ref, computed, defineComponent, watch, onMounted } from "vue";
import RadioButton from "primevue/radiobutton";

export default defineComponent({
  
  components: {
    RadioButton,
  },
  props: {
    initialData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const aberturaOcular = ref(null);
    const respostaVerbal = ref(null);
    const melhorRespostaMotora = ref(null);
    const reatividadePupilar = ref(null);

    // Carregar dados iniciais e garantir tipos corretos
    const carregarDadosIniciais = () => {
      aberturaOcular.value = props.initialData?.aberturaOcular || null;
      respostaVerbal.value = props.initialData?.respostaVerbal || null;
      melhorRespostaMotora.value =
      props.initialData?.melhorRespostaMotora || null;
      reatividadePupilar.value =
      props.initialData?.reatividadePupilar || null;
    };

    const aberturaOcularOptions = [
      { label: "+4 - Espontânea", value: 4 },
      { label: "+3 - Para comando verbal", value: 3 },
      { label: "+2 - Dor", value: 2 },
      { label: "+1 - Sem abertura dos olhos", value: 1 },
    ];

    const respostaVerbalOptions = [
      { label: "+5 - Orientado", value: 5 },
      { label: "+4 - Confuso", value: 4 },
      { label: "+3 - Palavras impróprias", value: 3 },
      { label: "+2 - Sons incompreensíveis", value: 2 },
      { label: "+1 - Sem resposta verbal", value: 1 },
    ];

    const melhorRespostaMotoraOptions = [
      { label: "+6 - Obedece aos comandos", value: 6 },
      { label: "+5 - Localiza a dor", value: 5 },
      { label: "+4 - Afasta a dor", value: 4 },
      { label: "+3 - Flexão a dor", value: 3 },
      { label: "+2 - Extensão a dor", value: 2 },
      { label: "+1 - Sem resposta motora", value: 1 },
    ];

    const reatividadePupilarOptions = [
      { label: "-2 - Nenhuma reatividade", value: -2 },
      { label: "-1 - Unilateral", value: -1 },
      { label: "0 - Reatividade bilateral", value: 0 },
    ];

    const totalPontos = computed(() => {
      return (
        (aberturaOcular.value || 0) +
        (respostaVerbal.value || 0) +
        (melhorRespostaMotora.value || 0) +
        (reatividadePupilar.value || 0)
      );
    });

    // Emitir dados sempre que os valores mudarem
    const emitirDados = () => {
      emit("update:escalaGlasgowData", {
        // Passe fichaId corretamente
        aberturaOcular: aberturaOcular.value,
        respostaVerbal: respostaVerbal.value,
        melhorRespostaMotora: melhorRespostaMotora.value,
        reatividadePupilar: reatividadePupilar.value,
        totalPontos: totalPontos.value,
      });
    };

    onMounted(() => {
      carregarDadosIniciais();
    });

    watch(
      [
        aberturaOcular,
        respostaVerbal,
        melhorRespostaMotora,
        reatividadePupilar,
      ],
      emitirDados,
      console.log(
        aberturaOcular.value,
        respostaVerbal.value,
        melhorRespostaMotora.value,
        reatividadePupilar.value,
        totalPontos.value
      )
    );

    return {
      aberturaOcularOptions,
      respostaVerbalOptions,
      melhorRespostaMotoraOptions,
      reatividadePupilarOptions,
      aberturaOcular,
      respostaVerbal,
      melhorRespostaMotora,
      reatividadePupilar,
      totalPontos,
    };
  },
});
</script>

<style scoped>
h2 {
  margin-top: 0;
  margin-bottom: 10px;
}
h3 {
  margin-top: 0;
  margin-bottom: 10px;
}
.ml-2 {
  margin-left: 0.5rem;
}
</style>
