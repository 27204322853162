<template>
  <div class="border-solid border-100 p-3 my-3">
    <h2 class="text-center">Avaliação Funcional</h2>
    <div
      class="flex justify-content-around surface-100 p-2 border-round-xl mb-2"
    >
      <div v-for="option in avaliacaoFuncionalOptions" :key="option">
        <RadioButton
          class="mr-2"
          :inputId="option"
          v-model="avaliacaoFuncional"
          :value="option"
        />
        <label :for="option">{{ option }}</label>
      </div>
    </div>
    <div class="flex flex-wrap justify-content-between">
      <!-- Consciência -->
      <div class="surface-100 p-3 border-round-xl mb-2">
        <h3 class="text-center">Consciência</h3>
        <hr class="w-8" />
        <div v-for="option in conscienciaOptions" :key="option">
          <RadioButton
            class="mr-2"
            :inputId="option"
            v-model="consciencia"
            :value="option"
          />
          <label :for="option">{{ option }}</label>
        </div>
      </div>
      <!-- Constantes -->
      <div class="surface-100 p-3 border-round-xl mb-2">
        <h3 class="text-center">Constantes</h3>
        <hr class="w-8" />
        <div v-for="option in constantesOptions" :key="option">
          <RadioButton
            class="mr-2"
            :inputId="option"
            v-model="constantes"
            :value="option"
          />
          <label :for="option">{{ option }}</label>
        </div>
      </div>
      <!-- Motricidade e Sensibilidade -->
      <div class="surface-100 p-3 border-round-xl mb-2">
        <h3 class="text-center">Motricidade e Sensibilidade</h3>
        <hr class="w-8" />
        <div v-for="option in motricidadeSensibilidadeOptions" :key="option">
          <RadioButton
            class="mr-2"
            :inputId="option"
            v-model="motricidadeSensibilidade"
            :value="option"
          />
          <label :for="option">{{ option }}</label>
        </div>
      </div>
      <!-- Aspecto da Pele -->
      <div class="surface-100 p-3 border-round-xl mb-2">
        <h3 class="text-center">Aspecto da Pele</h3>
        <hr class="w-8" />
        <div v-for="option in aspectoPeleOptions" :key="option">
          <RadioButton
            class="mr-2"
            :inputId="option"
            v-model="aspectoPele"
            :value="option"
          />
          <label :for="option">{{ option }}</label>
        </div>
      </div>
      <!-- Ruídos -->
      <div class="surface-100 p-3 border-round-xl mb-2">
        <h3 class="text-center">Ruídos</h3>
        <hr class="w-8" />
        <div v-for="option in ruidosOptions" :key="option">
          <RadioButton
            class="mr-2"
            :inputId="option"
            v-model="ruidos"
            :value="option"
          />
          <label :for="option">{{ option }}</label>
        </div>
      </div>
      <!-- Pulso Carotídeo -->
      <div class="surface-100 p-3 border-round-xl">
        <h3 class="text-center">Pulso Carotídeo</h3>
        <hr class="w-8" />
        <div v-for="option in pulsoCarotideoOptions" :key="option">
          <RadioButton
            class="mr-2"
            :inputId="option"
            v-model="pulsoCarotideo"
            :value="option"
          />
          <label :for="option">{{ option }}</label>
        </div>
      </div>
      <!-- Pulso Radial -->
      <div class="surface-100 p-3 border-round-xl">
        <h3 class="text-center">Pulso Radial</h3>
        <hr class="w-8" />
        <div v-for="option in pulsoRadialOptions" :key="option">
          <RadioButton
            class="mr-2"
            :inputId="option"
            v-model="pulsoRadial"
            :value="option"
          />
          <label :for="option">{{ option }}</label>
        </div>
      </div>
      <!-- Observações -->
      <div class="surface-100 p-3 border-round-xl">
        <h3 class="text-center">Observações</h3>
        <hr class="w-8" />
        <div v-for="option in observacoesOptions" :key="option">
          <RadioButton
            class="mr-2"
            :inputId="option"
            v-model="observacoes"
            :value="option"
          />
          <label :for="option">{{ option }}</label>
        </div>
      </div>
      <!-- Queixas -->
      <div class="surface-100 p-3 border-round-xl">
        <h3 class="text-center">Queixas</h3>
        <hr class="w-8" />
        <div v-for="option in queixasOptions" :key="option">
          <RadioButton
            class="mr-2"
            :inputId="option"
            v-model="queixas"
            :value="option"
          />
          <label :for="option">{{ option }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, defineComponent, onMounted } from "vue";
import RadioButton from "primevue/radiobutton";

export default defineComponent({
  components: {
    RadioButton,
  },
  props: {
    initialData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    // Estados reativos para armazenar as seleções
    const avaliacaoFuncional = ref(null);
    const consciencia = ref(null);
    const motricidadeSensibilidade = ref(null);
    const aspectoPele = ref(null);
    const ruidos = ref(null);
    const constantes = ref(null);
    const pulsoCarotideo = ref(null);
    const pulsoRadial = ref(null);
    const observacoes = ref(null);
    const queixas = ref(null);

    // Função para carregar dados iniciais
    const carregarDadosIniciais = () => {
      avaliacaoFuncional.value = props.initialData.avaliacaoFuncional || null;
      consciencia.value = props.initialData.consciencia || null;
      motricidadeSensibilidade.value =
        props.initialData.motricidadeSensibilidade || null;
      aspectoPele.value = props.initialData.aspectoPele || null;
      ruidos.value = props.initialData.ruidos || null;
      constantes.value = props.initialData.constantes || null;
      pulsoCarotideo.value = props.initialData.pulsoCarotideo || null;
      pulsoRadial.value = props.initialData.pulsoRadial || null;
      observacoes.value = props.initialData.observacoes || null;
      queixas.value = props.initialData.queixas || null;
    };

    onMounted(() => {
      carregarDadosIniciais();
    });

    // Observa as mudanças e emite os dados para o componente pai
    watch(
      [
        avaliacaoFuncional,
        consciencia,
        motricidadeSensibilidade,
        aspectoPele,
        ruidos,
        constantes,
        pulsoCarotideo,
        pulsoRadial,
        observacoes,
        queixas,
      ],
      () => {
        const data = {
          avaliacaoFuncional: avaliacaoFuncional.value,
          consciencia: consciencia.value,
          motricidadeSensibilidade: motricidadeSensibilidade.value,
          aspectoPele: aspectoPele.value,
          ruidos: ruidos.value,
          constantes: constantes.value,
          pulsoCarotideo: pulsoCarotideo.value,
          pulsoRadial: pulsoRadial.value,
          observacoes: observacoes.value,
          queixas: queixas.value,
        };
        emit("update:fichaRemocaoData", data);
      }
    );

    return {
      avaliacaoFuncionalOptions: [
        "Sem resposta",
        "Parada Circulatória",
        "Parada Respiratória",
      ],
      conscienciaOptions: [
        "Responde as perguntas",
        "Abre os olhos",
        "Desorientado: Tempo ou espaço",
        "Agitado",
        "Confuso",
        "Sonolento",
      ],
      motricidadeSensibilidadeOptions: [
        "Normal",
        "Não percebe toque",
        "Não movimenta as pernas",
        "Não Movimenta as mãos",
      ],
      aspectoPeleOptions: ["Normal", "Suor", "Cianose"],
      ruidosOptions: ["Gargarejo", "Assoviado", "Roncos", "Sibilos"],
      constantesOptions: [
        "Resp. Ampla",
        "Resp. Regular",
        "Resp. Silenciosa",
        "Superficial",
        "Irregular",
        "Barulhenta",
      ],
      pulsoCarotideoOptions: ["Regular", "Cheio", "Irregular", "Fraco"],
      pulsoRadialOptions: ["Não perceptível", "Cheio", "Fraco"],
      observacoesOptions: ["Palidez", "Frieza nos membros", "Marmoreio"],
      queixasOptions: ["Sede", "Frio", "Angústia"],
      avaliacaoFuncional,
      consciencia,
      motricidadeSensibilidade,
      aspectoPele,
      ruidos,
      constantes,
      pulsoCarotideo,
      pulsoRadial,
      observacoes,
      queixas,
    };
  },
});
</script>

<style scoped>
h2 {
  margin-top: 0;
  margin-bottom: 10px;
}
h3 {
  margin-top: 0;
  margin-bottom: 10px;
}
.ml-2 {
  margin-left: 0.5rem;
}
</style>
