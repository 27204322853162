<template>
  <div class="border-solid border-100 p-3 my-3">
    <h2 class="text-center">Avaliação de Trauma</h2>
    <div class="flex gap-4 justify-content-center">
      <!-- Pupilas -->
      <div class="surface-100 p-3 border-round-xl mb-2">
        <h3 class="text-center">Pupilas</h3>
        <hr class="w-8" />
        <div v-for="option in pupilasOptions" :key="option">
          <RadioButton
            class="mr-2"
            :inputId="option"
            v-model="pupilas"
            :value="option"
          />
          <label :for="option">{{ option }}</label>
        </div>
      </div>
      <!-- Abdômen -->
      <div class="surface-100 p-3 border-round-xl mb-2">
        <h3 class="text-center">Abdômen</h3>
        <hr class="w-8" />
        <div v-for="option in abdomenOptions" :key="option">
          <RadioButton
            class="mr-2"
            :inputId="option"
            v-model="abdomen"
            :value="option"
          />
          <label :for="option">{{ option }}</label>
        </div>
      </div>
      <!-- Avaliação -->
      <div class="surface-100 p-3 border-round-xl mb-2">
        <h3 class="text-center">Avaliação</h3>
        <hr class="w-8" />
        <div v-for="option in avOptions" :key="option">
          <div class="flex justify-content-between">
            <label>{{ option }}</label>
            <div>
              <RadioButton
                class="mx-2"
                :inputId="option + '-sim'"
                v-model="av[option]"
                :value="true"
              />
              <label :for="option + '-sim'">Sim</label>
              <RadioButton
                class="mx-2"
                :inputId="option + '-nao'"
                v-model="av[option]"
                :value="false"
              />
              <label :for="option + '-nao'">Não</label>
            </div>
          </div>
        </div>
      </div>
      <!-- Mucosas -->
      <div class="surface-100 p-3 border-round-xl mb-2">
        <h3 class="text-center">Mucosas</h3>
        <hr class="w-8" />
        <div v-for="option in mucosasOptions" :key="option">
          <RadioButton
            class="mr-2"
            :inputId="option"
            v-model="mucosas"
            :value="option"
          />
          <label :for="option">{{ option }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, defineComponent, onMounted } from "vue";
import RadioButton from "primevue/radiobutton";

export default defineComponent({
  components: {
    RadioButton,
  },
  props: {
    initialData: Object, // Recebe os dados iniciais
  },
  setup(props, { emit }) {
    const pupilas = ref(null);
    const abdomen = ref(null);
    const av = ref({});
    const mucosas = ref(null);
    

    const carregarDadosIniciais = () => {
      pupilas.value = props.initialData.pupilas || null;
      abdomen.value = props.initialData.abdomen || null;
      av.value = props.initialData.av || null;
      mucosas.value = props.initialData.mucosas || null;
    };
    const pupilasOptions = [
      "Normal",
      "Midriáticas",
      "Mióticas",
      "Anisocórica",
      "Fotoplégicas",
      "Isocóricas",
    ];

    const abdomenOptions = [
      "Aumentado",
      "Diminuído",
      "Plano",
      "Flácido",
      "Destendido",
    ];

    const avOptions = [
      "T.C.E.",
      "Otorragia",
      "T.R.M.",
      "Seq. AVC",
      "Rinorragia",
    ];

    const mucosasOptions = [
      "Corada",
      "Hipocoradas",
      "Hidratadas",
      "Desidratadas",
      "Ictérica",
    ];

    onMounted(() => {
      carregarDadosIniciais();
    });

    // Observa as mudanças e emite os dados para o componente pai
    watch([pupilas, abdomen, av, mucosas], () => {
      const data = {
        pupilas: pupilas.value,
        abdomen: abdomen.value,
        av: av.value,
        mucosas: mucosas.value,
      };
      emit("update:avaliacaoTraumaData", data);
    });

    return {
      pupilasOptions,
      abdomenOptions,
      avOptions,
      mucosasOptions,
      pupilas,
      abdomen,
      av,
      mucosas,
    };
  },
});
</script>

<style scoped>
h2 {
  margin-top: 0;
  margin-bottom: 10px;
}
h3 {
  margin-top: 0;
  margin-bottom: 10px;
}
.ml-2 {
  margin-left: 0.5rem;
}
</style>
