<template>
  <div>
    <Dialog :closable="false" v-model:visible="loading" modal>
      <ProgressSpinner />
    </Dialog>
    <button @click="toggleExpand" class="bg w-3 m-0 mb-4 expand-button">
      {{ expanded ? "Cancelar" : "Criar Usuário" }}
    </button>

    <div v-if="expanded" class="form-container">
      <form class="flex flex-column" @submit.prevent="createUser">
        <div class="flex justify-content-center flex-wrap gap-3">
          <div class="flex m-2">
            <InputGroupAddon>
              <span class="mr-2 material-symbols-outlined">
                account_circle
              </span>
              <label for="username">Username:</label>
            </InputGroupAddon>
            <InputText
              type="text"
              id="username"
              v-model="formData.username"
              required
            />
          </div>
          <div class="flex m-2">
            <InputGroupAddon>
              <span class="mr-2 material-symbols-outlined"> key </span>
              <label for="password">Password:</label>
            </InputGroupAddon>
            <Password
              id="password"
              v-model="formData.password"
              toggleMask
              promptLabel="Escreva sua Senha"
              weakLabel="Senha Fraca"
              mediumLabel="Senha Média"
              strongLabel="Senha nível CREPH! :)"
              required
            />
          </div>
          <div class="flex align-items-center" v-if="isAdmin">
            <InputGroupAddon>
              <span class="material-symbols-outlined"> shield_person </span>
              <label for="isAdmin">Admin:</label>
            </InputGroupAddon>
            <Checkbox
              class="ml-3"
              id="isAdmin"
              v-model="formData.is_admin"
              :binary="true"
            />
          </div>
          <div class="flex align-items-center">
            <InputGroupAddon>
              <span class="material-symbols-outlined"> badge </span>
              <label for="cargo">Cargo:</label>
            </InputGroupAddon>
            <select class="h-3rem" id="cargo" v-model="formData.cargo" required>
              <option value="Enfermeiro">Enfermeiro</option>
              <option value="Médico">Médico</option>
              <option value="TecEnfermagem">Téc. Enfermagem</option>
              <option value="Visualizador PMA">Visualizador PMA</option>
              <option value="Operador Central">Operador Central</option>
              <option value="Outro">Outro</option>
            </select>
          </div>
          <div class="flex align-items-center">
            <InputGroupAddon>
              <span class="material-symbols-outlined"> id_card </span>
              <label for="registro">Registro:</label>
            </InputGroupAddon>
            <InputText
              id="registro"
              v-model="formData.registro"
              :maxlength="registroMaxLength"
              :disabled="formData.cargo === 'Operador Central'"
              required
            />
          </div>
        </div>
        <button class="h-3rem w-3 m-0 my-4" type="submit">Salvar</button>
      </form>
      <p v-if="errorMessage">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import InputGroupAddon from "primevue/inputgroupaddon";
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";
import Password from "primevue/password";
export default {
  components: {
    InputText,
    Checkbox,
    InputGroupAddon,
    Dialog,
    ProgressSpinner,
    Password,
  },
  data() {
    return {
      registroMaxLength: null, // Inicialmente sem limite
      loading: false,
      formData: {
        username: "",
        password: "",
        is_admin: false,
        cargo: "",
        registro: "",
      },
      errorMessage: "",
      maxAttempts: 3, // Número máximo de tentativas
      expanded: false, // Inicialmente, o formulário não está expandido
    };
  },
  computed: {
    // Computed properties to check user cargo
    isMedico() {
      return localStorage.getItem("cargo") === "Médico";
    },
    isEnfermeiro() {
      return localStorage.getItem("cargo") === "Enfermeiro";
    },
    isTecEnfermagem() {
      return localStorage.getItem("cargo") === "TecEnfermagem";
    },
    isVisualizadorPMA() {
      return localStorage.getItem("cargo") === "Visualizador PMA";
    },
    isOperadorCentral() {
      return localStorage.getItem("cargo") === "Operador Central";
    },
  },
  watch: {
    "formData.cargo"(newCargo) {
      if (newCargo === "Médico") {
        this.formData.registro = "CRM - ";
        this.registroMaxLength = 11;
      } else if (newCargo === "Enfermeiro" || newCargo === "TecEnfermagem") {
        this.formData.registro = "COREN - ";
        this.registroMaxLength = 14;
      } else if (
        newCargo === "Outro" ||
        newCargo === "Visualizador PMA" ||
        newCargo === "Visualizador PMA" ||
        newCargo === "Operador Central"
      ) {
        this.formData.registro = ""; // Limpa o registro se o cargo não corresponder a nenhum caso específico
        this.registroMaxLength = null; // Remove o limite de caracteres
      }
    },
  },
  created() {
    this.checkAdminStatus();
  },
  methods: {
    checkAdminStatus() {
      // Verifica o status de administrador no localStorage
      const isAdmin = localStorage.getItem("isAdmin");
      if (isAdmin === "true") {
        this.isAdmin = true;
      }
    },
    async createUser() {
      let tentativasRestantes = this.maxAttempts;

      while (tentativasRestantes > 0) {
        try {
          this.loading = true;
          const response = await fetch(
            "https://backendcreph.onrender.com/api/admin/user",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                username: this.formData.username,
                password: this.formData.password,
                is_admin: this.formData.is_admin,
                cargo: this.formData.cargo,
                registro: this.formData.registro,
              }),
            }
          );

          const data = await response.json();

          if (!response.ok) {
            if (response.status === 401) {
              throw new Error("Você não tem permissão para criar usuários.");
            } else if (response.status === 400) {
              alert("Dados inválidos");
              throw new Error(
                data.message || "Dados inválidos para criar usuário."
              );
            } else {
              this.loading = false;
              this.formData.username = "";
              alert("Usuário já está em uso");
              return;
            }
          }

          this.loading = false;
          alert("Usuário criado com sucesso!");

          this.formData.username = "";
          this.formData.password = "";
          this.formData.is_admin = false;
          this.formData.cargo = "";
          this.formData.registro = "";
          this.errorMessage = "";

          // Recarrega a página para exibir a lista atualizada de usuários
          window.location.reload();

          return;
        } catch (error) {
          console.error("Erro ao criar usuário:", error);
          if (error.message === "Você não tem permissão para criar usuários.") {
            window.alert("Você não tem permissão para criar usuários.");
          } else if (error.message === "Dados inválidos para criar usuário.") {
            window.alert("Dados inválidos para criar usuário.");
          }
          this.errorMessage = `Aguarde...`;
          tentativasRestantes--;
          await new Promise((resolve) => setTimeout(resolve, 1000)); // Aguarda antes de tentar novamente
        }
      }

      if (tentativasRestantes === 0) {
        this.loading = false;
        this.errorMessage =
          "Falha ao criar usuário após várias tentativas. Por favor, tente novamente mais tarde.";
        window.alert(this.errorMessage);
      }
    },
    toggleExpand() {
      this.expanded = !this.expanded; // Alternar o estado de expansão do formulário
    },
  },
};
</script>

<style scoped>
/* Estilos específicos para este componente */

.form-container {
  margin-top: 20px;
}

.expand-button {
  height: 3rem;
  margin-bottom: 10px;
  background-color: darkblue;
  color: white;
  border: 1px solid #2196f3;
  padding: 8px 16px;
  cursor: pointer;
}

.expand-button:hover {
  background-color: white;
  color: darkblue;
}
</style>
