<template>
  <div class="border-solid border-100 p-3 my-3">
    <h2 class="text-center">Controle de Acesso e Dreno</h2>
    <div class="p-inputgroup mb-3">
      <div
        class="w-full flex justify-content-around surface-100 p-2 border-round-xl"
      >
        <div v-for="option in controleAcessoDrenoOptions" :key="option">
          <RadioButton
            class="mr-2"
            :inputId="option"
            v-model="controleAcessoDreno"
            :value="option"
            @change="emitirDados"
          />
          <label :for="option">{{ option }}</label>
        </div>
      </div>
    </div>
    <h2 class="text-center">Evolução de Remoção</h2>
    <!-- Evolução de Enfermagem -->
    <div class="p-inputgroup mb-3">
      <InputGroupAddon class="w-3">
        <span class="material-icons">medical_information</span>
        <div>Evolução de Enfermagem</div>
      </InputGroupAddon>
      <InputText
        v-model="evolucaoEnfermagem"
        placeholder="Digite a evolução de enfermagem"
        @input="emitirDados"
      />
    </div>

    <!-- Evolução Médica -->
    <div class="p-inputgroup mb-3">
      <InputGroupAddon class="w-3">
        <span class="material-icons mr-2">medical_information</span>
        <div>Evolução Médica</div>
      </InputGroupAddon>
      <InputText
        v-model="evolucaoMedica"
        placeholder="Digite a evolução médica"
        @input="emitirDados"
      />
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, watch } from "vue";
import InputText from "primevue/inputtext";
import InputGroupAddon from "primevue/inputgroupaddon";
import RadioButton from "primevue/radiobutton";

export default defineComponent({
  components: {
    InputText,
    InputGroupAddon,
    RadioButton,
  },
  props: {
    initialData: Object, // Recebe os dados iniciais
  },
  setup(props, { emit }) {
    const evolucaoEnfermagem = ref(props.initialData.evolucaoEnfermagem || "");
    const evolucaoMedica = ref(props.initialData.evolucaoMedica || "");
    const controleAcessoDreno = ref(
      props.initialData.controleAcessoDreno || ""
    );

    const controleAcessoDrenoOptions = [
      "A.V.P.",
      "A.V. Central",
      "S. Nasogástrica",
      "S. Vesical",
      "Dreno Torácico",
      "Não se aplica"
    ];

    const emitirDados = () => {
      const data = {
        evolucaoEnfermagem: evolucaoEnfermagem.value,
        evolucaoMedica: evolucaoMedica.value,
        controleAcessoDreno: controleAcessoDreno.value,
      };
      emit("update:evolucaoRemocaoData", data);
    };

    // Emitir dados iniciais
    watch([evolucaoEnfermagem, evolucaoMedica, controleAcessoDreno], () => {
      emitirDados();
    });

    return {
      evolucaoEnfermagem,
      evolucaoMedica,
      controleAcessoDreno,
      controleAcessoDrenoOptions,
      emitirDados,
    };
  },
});
</script>

<style scoped>
h2 {
  margin-top: 0;
  margin-bottom: 10px;
}
.p-inputgroup-addon {
  width: 200px;
  text-align: center;
}
</style>
